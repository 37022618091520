import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "../Table/DataTable";
import { GetSecurityScanUrl } from "../../../../routes";
import {
  GetCognitoUserTokenCredentials,
  GetLoginCredentialsAsJsonObject,
  GetUserLoginCredentials,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from "../../../../components/UserCredentials";
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from "../../../../components/CognitoUserPoolSignin";
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from "../../../../components/Login";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

const ListView = ({
  data,
  onBack,
  selectedCompliance,
  filters,
  selectedScanId,
}) => {
  const [findingsData, setFindingsData] = useState([]);
  const { status = "", selectedData = {} } = data || {};
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,  // Total records from the server
  });

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    let authProviderTypeStr = "";

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    }
    if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
    }

    let credJson = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
      authProviderType: authProviderTypeStr,
    };

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      credJson.cognito_user_pool_email_password_info =
        GetCognitoUserTokenCredentials();
    }

    return credJson;
  };

  const getSecurityComplianceDetailsPayload = (pagination) => {
    let filters_args = {
      accounts: [filters["AWS Account"]],

      finding_status: [status],

      severity_type: ["High", "Low", "Medium"],

      compliance_type: [selectedCompliance],
      // compliance_type: [
      //   "aws_well_architected_framework_security_pillar_aws.csv",
      // ],

      region_type: [],
    };

    return {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "get_compliance_details",
      command_args: {
        AccountId: filters["AWS Account"],
        ExpectedScanId: selectedScanId,
        filters: filters_args,
        startRow: ((pagination.current - 1) * pagination.pageSize).toString(),
        numRows: (pagination.pageSize).toString(),
      },
    };
  };

  const handleGetComplianceDetails = async (pagination) => {
    const url = GetSecurityScanUrl();
    const payload = getSecurityComplianceDetailsPayload(pagination);
    console.log("list view payload", payload);
    try {
      setLoading(true);
      const resp = await axios.post(url, payload);
      if (resp.status === 200) {
        console.log("details", resp.data);
      }
      return resp;
    } catch (err) {
      console.log("handleStartSecurityScan to " + url + " failed");
    } finally {
      const { passed, failed, manual, total_tests } = selectedData;

      const total = (() => {
        switch (status) {
          case "PASS":
            return passed;
          case "FAIL":
            return failed;
          case "MANUAL":
            return manual;
          default:
            return total_tests;
        }
      })();
      setPagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: total, // Assuming 'total' is the total number of records
      });
      setLoading(false);
    }
  };

  const getFindings = async (pagination) => {
    try {
      const responseData = await handleGetComplianceDetails(pagination);
      if (responseData) {
        const findings = responseData?.data?.findings || [];

        let processedFindings = [...findings];

        setFindingsData(processedFindings);
      }
    } catch (error) {
      console.error("Error fetching compliance details:", error);
    }
  };

  useEffect(() => {
    getFindings(pagination);
  }, [status, selectedData]);

  const handleTableChange = (pagination) => {
    getFindings(pagination)
  };

  return (
    <div
      style={{
        height: "75vh",
        overflow: "auto",
      }}
    >
      <Button
        className="back-btn"
        onClick={() => {
          console.log("Back button in ListView clicked.");
          onBack();
        }}
        icon={<ArrowLeftOutlined />}
      >
        Back
      </Button>
      <DataTable
        data={findingsData}
        columnType={selectedCompliance}
        loading={loading}
        title={() => selectedCompliance.split('_').map(word => word.toUpperCase()).join(' ') + '-' + status}
        pagination={pagination}
        onChange={handleTableChange} />
    </div>
  );
};

export default ListView;
