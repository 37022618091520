import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  Steps,
  Button,
  Typography,
  message,
  Tabs,
  Checkbox,
  Input,
} from "antd";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { GetIamPolicyDownloadUrl } from "../../routes";
import { GetLoginCredentials } from "../UserCredentials";
import "./AwsAccountSetupAndVerifier.css";
import awsConsole from "../../images/awsStep2.jpeg";
import runStep from "../../images/awsStep5.jpeg";

import scriptStep from '../../images/awsStep1.jpeg'

const { Step, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const AwsAccountSetupAndVerifier = ({ account, onClose, onVerified }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [scriptModalVisible, setScriptModalVisible] = useState(false);
  const [scripts, setScripts] = useState({
    defaultBfwRole: "",
    awsCur: "",
    securityManager: "",
    uniqueId: "",
  });
  const [isScriptRun, setIsScriptRun] = useState(false);
  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const IntroductionStepContent = () => {
    return {
      title: "Introduction",
      content: (
        <div>
          <h4>AWS Account Verification Process</h4>

          <div className="process-steps">
            <div className="step">
              <div className="step-number">1</div>
              <div className="step-content">
                <h5>Setup AWS account for management</h5>
                <p>
                  Configure your AWS account to allow our system to manage it
                  securely.
                </p>
              </div>
              <div className="step-connector"></div>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <div className="step-content">
                <h5>Prove ownership of the AWS account</h5>
                <p>
                  Demonstrate that you have administrative access to the AWS
                  account.
                </p>
              </div>
            </div>
          </div>

          <div className="verification-info">
            <h5>What is the verification step?</h5>
            <p>
              The verification step has two parts, setting up your AWS account
              and proving your account ownership
              <ul>
                <li>
                  The AWS account to be managed has to be setup for access by
                  our cloud management system to access the various AWS cloud
                  resource configuration and information
                </li>
                <li>
                  Cloud management system wants to ensure the real owner of the
                  account by checking access for security reasons
                </li>
              </ul>
            </p>

            <h5>Why is it necessary?</h5>
            <p>
              Verification is crucial for several reasons:
              <ul>
                <li>
                  AWS account owner should understand permissions granted to
                  cloud management suite
                </li>
                <li>
                  AWS account should be setup for cloud management suite to
                  access the AWS account, without this cloud management suite
                  can't work
                </li>
                <li>
                  No cloud management suite user can use any account
                  intentionally or by mistake
                </li>
                <li>
                  It allows our system to securely manage and optimize your AWS
                  resources.
                </li>
                <li>
                  It helps prevent potential misuse or accidental changes to
                  your AWS environment.
                </li>
              </ul>
            </p>

            <h5>What changes will happen in the AWS account?</h5>
            <p>
              During the verification process, the following changes will occur
              in your AWS account:
              <ul>
                <li>
                  IAM roles will be created and required permissions will be
                  granted
                </li>
                <li>
                  A new bucket will be created and Cost and Usage reports will
                  be stored hourly, the bucket contents will be replicated to a
                  Bluefinware bucket location
                </li>
                <li>
                  A one time Access key and secret key is required for
                  verification
                </li>
                <li>
                  After verification is complete the access key and secret key
                  can be removed
                </li>
                <li>
                  Establishment of secure cross-account access mechanisms would
                  be done on the cloud management suite side
                </li>
              </ul>
              Don't worry, we'll guide you through each step, and you'll have
              full visibility into all changes made.
            </p>
          </div>
        </div>
      ),
    };
  };

  const SetupStepsContent = () => {
    const getSetupScriptDownloadPayload = () => {
      return {
        concerto_user_credentials: GetLoginCredentials(),
        command_type: "get_aws_setup_script",
        command_args: {
          customerAwsId: account.AccountId,
        },
      };
    };

    const getConfirmSetupPayload = () => {
      return {
        concerto_user_credentials: GetLoginCredentials(),
        command_type: "confirm_aws_account_setup_done",
        command_args: {
          customerAwsId: account.AccountId,
          uniqueId: scripts.uniqueId,
        },
      };
    };

    function decodeBase64(base64String) {
      try {
        return atob(base64String);
      } catch (error) {
        console.error("Invalid base64 string:", error);
        return null;
      }
    }

    const handleDownload = async () => {
      const url = GetIamPolicyDownloadUrl();
      const payload = getSetupScriptDownloadPayload();

      try {
        const resp = await axios.post(url, payload);
        if (resp.status === 200) {
          const decodedData = decodeBase64(resp.data);
          const decodedDataObj = JSON.parse(decodedData);

          const defaultBfwRoleContentsBase64 =
            decodedDataObj["default_bluefinware_role_contents"];
          const defaultBfwRoleContentsStr = decodeBase64(
            defaultBfwRoleContentsBase64
          );

          const curScriptBase64 = decodedDataObj["cur_script_contents"];
          const uniqueId = decodedDataObj["unique_id"];
          const curScriptStrB64 = decodeBase64(curScriptBase64);
          const awsCurScriptObj = JSON.parse(curScriptStrB64);
          const awsCurScriptBase64 = awsCurScriptObj["aws_cur_script"];
          const awsCurScriptStr = decodeBase64(awsCurScriptBase64);

          const securityManagerScriptBase64 =
            decodedDataObj["security_manager_role_contents"];
          const securityManagerScript = decodeBase64(
            securityManagerScriptBase64
          );

          setScripts({
            defaultBfwRole: defaultBfwRoleContentsStr,
            awsCur: awsCurScriptStr,
            securityManager: securityManagerScript,
            uniqueId: uniqueId,
          });

          setScriptModalVisible(true);
          toast.success("AWS setup scripts downloaded successfully");
        }
      } catch (err) {
        console.log("Failed to get IAM policy:", err);
        toast.error("Failed to download AWS setup script");
      }
    };

    const handleConfirmSetup = async () => {
      const url = GetIamPolicyDownloadUrl();
      const payload = getConfirmSetupPayload();

      try {
        const resp = await axios.post(url, payload);
        if (resp.status === 200) {
          toast.success("AWS account setup confirmed successfully");
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        console.log("Failed to confirm AWS account setup:", err);
        toast.error("Failed to confirm AWS account setup");
      }
    };


    // scriptStep

    return {
      title: "Setup",
      content: (
        <div>
          <h4>Setup AWS Account</h4>
          <p>Follow these steps to set up your AWS account for management:</p>
          <ol>
            <li>
              <p> Download the AWS setup script by clicking on the Get Setup Script button below</p>
              <img
                src={scriptStep}
                alt="Downnload setup script"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  marginBottom: "10px",
                }}
              />

              <div>
                <Button
                  style={{ marginBottom: "10px" }}
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handleDownload}
                >
                  Get Setup Script
                </Button>
              </div>
            </li>
            <li>
              <p> Go to AWS Console & Open CloudShell</p>
              <img
                src={awsConsole}
                alt="AWS Console & CloudShell"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  marginBottom: "10px",
                }}
              />
            </li>
            <li>
              <p> In the CloudShell, enter the following command</p>
              <p>
                <code>nano setup.py</code>
              </p>
            </li>
            <li>
              <p>
                Paste the above copied script content in the nano editor
                & save it
              </p>
            </li>
            <li>
              <p>
                Step 5: Enter the command <code>sh ./setup.sh</code> & press
                enter
              </p>
              <img
                src={runStep}
                alt="Run setup.sh command"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  marginBottom: "10px",
                }}
              />
            </li>
          </ol>
          <div style={{ marginTop: "20px" }}>
            <Checkbox
              checked={isScriptRun}
              onChange={(e) => setIsScriptRun(e.target.checked)}
            >
              Acknowledge, the scripts have been run successfully
            </Checkbox>
          </div>
        </div>
      ),
    };
  };

  const VerificationStepsContent = () => {
    const verificationText = `# Step 1: Create a new user
    aws iam create-user --user-name concerto_test_01kyz
    
    # Step 2: Create access and secret key for the new user
    aws iam create-access-key --user-name concerto_test_01kyz

    The output of the create-access-key command will look like this:
    {
        "AccessKey": {
            "UserName": "concerto_test_01kyz",
            "AccessKeyId": "you will see some key here within these double quotes copy that",
            "Status": "Active",
            "SecretAccessKey": "you will see some key here within these double quotes copy that",
            "CreateDate": "2024-09-16T12:34:56Z"
        }
    }
    
    Copy paste the AccessKeyId and SecretAccessKey in the fields below:
    
    #Step 3: Delete the newly created account, we don't need it anymore
    
    Get the access key id using command
    aws iam list-access-keys --user-name concerto_test_01kyz
    
    Delete the access using the access key id from above step
    aws iam delete-access-key --user-name concerto_test_01kyz --access-key-id <ACCESS_KEY_ID>
    
    Delete the User created now for verification
    aws iam delete-user --user-name concerto_test_01kyz`;

    return {
      title: "Verify",
      content: (
        <div>
          <h4>Verify Account Ownership</h4>
          <div
            style={{
              backgroundColor: "#f0f8ff",
              border: "1px solid #91d5ff",
              borderRadius: "4px",
              padding: "16px",
              marginTop: "16px",
              marginBottom: "16px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Typography.Text
              style={{ whiteSpace: "pre-wrap", color: "#0050b3" }}
            >
              {verificationText}
            </Typography.Text>
          </div>
          <div style={{ marginBottom: "16px" }}>
            <div style={{ marginBottom: "8px" }}>
              <label
                htmlFor="accessKeyId"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Access Key ID:
              </label>
              <Input
                id="accessKeyId"
                placeholder="Enter your Access Key ID"
                value={accessKeyId}
                onChange={(e) => setAccessKeyId(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="secretAccessKey"
                style={{ display: "block", marginBottom: "4px" }}
              >
                Secret Access Key:
              </label>
              <Input
                id="secretAccessKey"
                placeholder="Enter your Secret Access Key"
                value={secretAccessKey}
                onChange={(e) => setSecretAccessKey(e.target.value)}
                type="password"
              />
            </div>
          </div>
        </div>
      ),
    };
  };

  const steps = [
    IntroductionStepContent(),
    SetupStepsContent(),
    VerificationStepsContent(),
  ];

  const handleVerify = async () => {
    const url = GetIamPolicyDownloadUrl();
    const payload = {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "verify_aws_account_ownership",
      command_args: {
        customerAwsId: account.AccountId,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      },
    };

    try {
      const resp = await axios.post(url, payload);
      console.log("API response:", resp.data);
      if (resp.status === 200) {
        if (resp.data === "b99237e9-833c-48ac-b766-76b61ba0d715") {
          setVerificationSuccess(true);
          toast.success("Account verified successfully");
          onVerified(account);
        } else {
          toast.error(
            "Verification failed. Please check your credentials and try again."
          );
        }
      }
    } catch (error) {
      console.error("Failed to verify account:", error);
      toast.error("Failed to verify account. Please try again.");
    }
  };

  const handleFinish = () => {
    onClose();
  };

  const scriptPreStyle = {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    maxHeight: "400px",
    overflow: "auto",
    backgroundColor: "#f5f5f5",
    padding: "10px",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "1.5",
  };

  const ScriptTab = ({ title, content }) => (
    <div>
      <Paragraph
        copyable={{
          text: content,
          onCopy: () => message.success(`${title} script copied to clipboard`),
        }}
      >
        <Button icon={<CopyOutlined />}>Copy {title} Script</Button>
      </Paragraph>
      <pre style={scriptPreStyle}>{content}</pre>
    </div>
  );

  const handleScriptsDownload = (uniqueId) => {
    const consolidatedContent = `# Unique ID: ${uniqueId}
  
  # Default BFW Role Script
  # This script sets up the default BluefinWare role in your AWS account
  ${scripts.defaultBfwRole}
  
  # AWS CUR (Cost and Usage Report) Script
  # This script configures the AWS Cost and Usage Report and sets up the necessary S3 bucket
  ${scripts.awsCur}
  
  # Security Manager Script
  # This script creates and configures the Security Manager role for enhanced security management
  ${scripts.securityManager}`;

    // Create a blob and a link to download the file
    const blob = new Blob([consolidatedContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `script_${uniqueId}.txt`; // Specify the file name

    // Trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Show success message
    message.success("Downloaded successfully!");
  };

  const ConsolidatedScriptsTab = ({ scripts }) => {
    const consolidatedContent = `# Unique ID: ${scripts.uniqueId}

# Default BFW Role Script
# This script sets up the default BluefinWare role in your AWS account
${scripts.defaultBfwRole}

# AWS CUR (Cost and Usage Report) Script
# This script configures the AWS Cost and Usage Report and sets up the necessary S3 bucket
${scripts.awsCur}

# Security Manager Script
# This script creates and configures the Security Manager role for enhanced security management
${scripts.securityManager}`;

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div>
            <strong>Unique ID:</strong> {scripts.uniqueId}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Paragraph
              style={{ marginBottom: 0, marginRight: "10px" }}
              copyable={{
                text: scripts.uniqueId,
                onCopy: () => message.success("Unique ID copied to clipboard"),
              }}
            ></Paragraph>

            <DownloadOutlined
              style={{ fontSize: "16px", cursor: "pointer", color: "#1890ff" }}
              onClick={() => handleScriptsDownload(scripts.uniqueId)}
            />
          </div>
        </div>

        <pre style={scriptPreStyle}>{consolidatedContent}</pre>
      </div>
    );
  };

  return (
    <>
      <Modal
        visible={true}
        title="AWS Account Setup and Verification"
        onCancel={onClose}
        footer={null}
        width={700}
        className="aws-modal"
      >
        <Steps current={currentStep} className="steps-content">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="aws-modal-content-wrapper">
          {verificationSuccess ? (
            <div>
              <h4>Verification Successful</h4>
              <p>
                Your AWS account has been successfully verified and set up for
                management.
              </p>
            </div>
          ) : (
            steps[currentStep].content
          )}
        </div>
        <div className="steps-action">
          {!verificationSuccess && (
            <>
              {currentStep > 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Previous
                </Button>
              )}
              {currentStep === 1 && (
                <Button
                  type="primary"
                  onClick={() => setCurrentStep(currentStep + 1)}
                  disabled={!isScriptRun} // Disable only for Step 2
                >
                  Next
                </Button>
              )}
              {currentStep !== 1 && currentStep < steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Next
                </Button>
              )}
              {currentStep === steps.length - 1 && !verificationSuccess && (
                <Button type="primary" onClick={handleVerify}>
                  Verify
                </Button>
              )}
            </>
          )}
          {verificationSuccess && (
            <Button type="primary" onClick={handleFinish}>
              Finish
            </Button>
          )}
        </div>
      </Modal>
      <Modal
        visible={scriptModalVisible}
        title="AWS Setup - Script content"
        onCancel={() => setScriptModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setScriptModalVisible(false)}>
            Close
          </Button>,
        ]}
        width={800}
      >
        <ConsolidatedScriptsTab scripts={scripts} />
      </Modal>
    </>
  );
};

export default AwsAccountSetupAndVerifier;
