import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Tooltip, Spin, Typography, Card } from 'antd';
import { FileSearchOutlined, LinkOutlined, CodeOutlined, RobotOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from "axios";
import "../../../../styles/DataTable.css";
import { GetAiGenQueryResponsetUrl, getCarloIpFromBff } from "../../../../routes";
import { GetLoginCredentialsAsJsonObject } from "../../../../components/UserCredentials";
import Markdown from 'react-markdown'

const { Title, Paragraph } = Typography;

// Utility functions for color coding
const getStatusColor = (status) => {
  switch (status) {
    case "PASS":
      return "green";
    case "FAIL":
      return "red";
    case "MANUAL":
      return "darkblue";
    default:
      return "black";
  }
};

const getSeverityColor = (severity) => {
  switch (severity) {
    case "low":
      return "blue";
    case "medium":
      return "brown";
    case "high":
      return "red";
    case "critical":
      return "lightcoral";
    default:
      return "black";
  }
};

// Column definition with View Details button
const getColumns = (handleViewDetails) => [
  {
    title: "Account ID",
    dataIndex: "AccountID",
    key: "accountId",
    className: "custom-header",
  },
  {
    title: "Region",
    dataIndex: "Region",
    key: "region",
    className: "custom-header",
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "service",
    className: "custom-header",
  },
  {
    title: "Check ID",
    dataIndex: "CheckID",
    key: "checkId",
    className: "custom-header",
  },
  {
    title: "Check Title",
    dataIndex: "CheckTitle",
    key: "checkTitle",
    className: "custom-header",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "status",
    className: "custom-header",
    render: (text) => (
      <span className={`status-text ${text.toLowerCase()}`} style={{ color: getStatusColor(text) }}>
        {text}
      </span>
    ),
  },
  {
    title: "Status Details",
    dataIndex: "StatusDetails",
    key: "statusDetails",
    className: "custom-header",
  },
  {
    title: "Severity",
    dataIndex: "Severity",
    key: "severity",
    className: "custom-header",
    render: (text) => (
      <span
        className={`severity-text ${text.toLowerCase()}`}
        style={{ color: getSeverityColor(text) }}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Actions",
    key: "actions",
    className: "custom-header",
    render: (_, record) => (
      <Tooltip title="View Details">
        <Button
          type="link"
          icon={<FileSearchOutlined />}
          onClick={() => handleViewDetails(record)}
        />
      </Tooltip>
    ),
  },
];

// Main component
const DataTable = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [loadingAI, setLoadingAI] = useState(false);
  const [aiInfo, setAIInfo] = useState(null);
  const [carloIp, setCarloIp] = useState(null);

  useEffect(() => {
    getCarloIp();
  }, []);

  const getCarloIp = async () => {
    const carloIp = await getCarloIpFromBff();
    setCarloIp(carloIp);
  };
  // Show modal with selected record details
  const handleViewDetails = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    setAIInfo(null); // Reset AI info when opening modal
  };

  // Close modal
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const getAwsKeysReadPayload = (query) => {
    let keysReadPayload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
      module: "security_expert",
      query: query
    };
    return keysReadPayload;
  };

  // Fetch AI info on button click
  const requestMoreInfo = async (query) => {
    setLoadingAI(true);
    const url = GetAiGenQueryResponsetUrl(carloIp)
    const payload = getAwsKeysReadPayload(query);
    try {
      const response = await axios.post(url, payload);
      setAIInfo(response.data);
    } catch (error) {
      setAIInfo({ error: "Unable to fetch AI info." });
    } finally {
      setLoadingAI(false);
    }
  };

  return (
    <div className="dataTable-container">
      <Table
        dataSource={data}
        columns={getColumns(handleViewDetails)}
        pagination={{ position: ["topRight"], pageSize: 20 }}
        className="custom-dataTable"
        scroll={{ x: "100%" }}
      />

      {/* Modal for displaying detailed information */}
      <Modal
        title={<Title level={4}><InfoCircleOutlined /> Remediation Details</Title>}
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width={800}
      >
        {selectedRecord ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            
            {/* Section 1: Info */}
            <Card
              title={<Title level={5}><InfoCircleOutlined /> General Information</Title>}
              bordered={true}
            >
              <Paragraph><strong>Account ID:</strong> {selectedRecord.AccountID}</Paragraph>
              <Paragraph><strong>Region:</strong> {selectedRecord.Region}</Paragraph>
              <Paragraph><strong>Service:</strong> {selectedRecord.Service}</Paragraph>
              <Paragraph><strong>Check ID:</strong> {selectedRecord.CheckID}</Paragraph>
              <Paragraph><strong>Status Details:</strong> {selectedRecord.StatusDetails}</Paragraph>
              <Paragraph><strong>Severity:</strong> {selectedRecord.Severity}</Paragraph>
            </Card>

            {(selectedRecord.RemediationRecommendationText ||selectedRecord.RemediationRecommendationURL) &&<Card
              title={<Title level={5}><LinkOutlined /> AWS Recommendation</Title>}
              bordered={true}
            >
              <Paragraph>
                {selectedRecord.RemediationRecommendationText}
                {selectedRecord.RemediationRecommendationURL && <a href={selectedRecord.RemediationRecommendationURL} target="_blank" rel="noopener noreferrer">
                  {selectedRecord.RemediationRecommendationURL}
                </a>}
              </Paragraph>
            </Card>}

            {selectedRecord.RemediationCodeCLI && !selectedRecord.RemediationCodeCLI.includes("prowler") && (<Card
              title={<Title level={5}><CodeOutlined /> CLI Command</Title>}
              bordered={true}
            >
              <Paragraph code>{selectedRecord.RemediationCodeCLI}</Paragraph>
            </Card>)}

            {/* Section 4: AI Request */}
            <Card
              title={<Title level={5}><RobotOutlined /> AI Assistance</Title>}
              bordered={true}
              extra={
                <Button
                  type="primary"
                  onClick={()=>requestMoreInfo(selectedRecord.RemediationRecommendationText)}
                  disabled={loadingAI}
                >
                  Ask Carlo AI
                </Button>
              }
            >
              {loadingAI ? <Spin /> : (
                aiInfo ? (
                  aiInfo.error || aiInfo.result.result_data.status_code != 200 ? (
                    <Paragraph style={{ color: 'red' }}>{aiInfo.error}</Paragraph>
                  ) : (
                    <Markdown>{aiInfo.result.result_data.result_data}</Markdown>
                  )
                ) : (
                  <Paragraph>Click the button to get AI-generated insights.</Paragraph>
                )
              )}
            </Card>

          </div>
        ) : (
          <Paragraph>No details available.</Paragraph>
        )}
      </Modal>
    </div>
  );
};

export default DataTable;
