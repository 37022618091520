import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftMenu from "../LeftMenu";
import { GetMenuBar } from "../MenubarComponent";
import "../../styles/ProductConfiguration.css";
import Schedules from "./Schedules/Schedules";
import AwsAccessKeys from "../Settings/AwsAccessKeys";
import { Clock, ClockFill } from "react-bootstrap-icons";

function ProductConfiguration({ defaultActivePage = "" }) {
  const [activePage, setActivePage] = useState(defaultActivePage);

  const getActivePage = () => {
    switch (activePage) {
      case "Schedules":
        return <Schedules />;
      case "AWS Accounts":
        return <AwsAccessKeys />;
      default:
        return <div>Coming Soon</div>;
    }
  };

  activePage === "Schedules" ? <Schedules /> : <div>Coming Soon</div>;

  return (
    <>
      <div className="productConfig">
        <>
          <div xs lg="9">
            <div className="title">
              <span
                onClick={() => setActivePage("")}
                style={{ cursor: "pointer" }}
              >
                Product Configuration
              </span>
              {activePage ? (
                <>
                  {" > "}
                  <span className="active-page">{activePage}</span>
                </>
              ) : (
                ""
              )}{" "}
            </div>

            {/* <div className='col mb-4' key={index}>
                <div className='custom-card card h-100 d-flex flex-column align-items-center p-3' onClick={() => setActivePage('Schedules')}>
                <Clock color='grey' />
                  <div className='card-body text-center'>
                    <h5 className='card-title'>
                      <div to='#' className='custom-link'>
                      Schedules
                      </div>
                    </h5>
                  </div>
                </div>
              </div> */}

            {!activePage ? (
              <div className="Product-config container mt-5">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4">
                  <div className="col mb-4">
                    <div
                      className="Product-config-custom-card card h-100 d-flex flex-column align-items-center p-3"
                      onClick={() => setActivePage("AWS Accounts")}
                    >
                      <img
                        src="https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png"
                        alt=""
                        className="Product-config-card-img-top img-fluid"
                      />
                      <div className="Product-config-card-body text-center">
                        <h6>AWS ACCOUNTS</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col mb-4">
                    <div
                      className="Product-config-custom-card card h-100 d-flex flex-column align-items-center p-3"
                      onClick={() => setActivePage("Schedules")}
                    >
                      <Clock
                        color="grey"
                        style={{ fontSize: "40px", margin: "10px 0 20px 0" }}
                      />
                      <div className="Product-config-card-body text-center">
                        <h5>Schedules</h5>
                      </div>
                    </div>
                  </div>
                  {/* <div onClick={() => setActivePage('Assets')}>
                  <div>Assets</div>
                  <div>{'>'}</div>
                </div> */}
                </div>
              </div>
            ) : (
              getActivePage()
            )}
          </div>
        </>
      </div>
    </>
  );
}

export default ProductConfiguration;
