import { Select, Row, Col, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { GetSecurityScanUrl } from "../../../../routes";
import {
  GetCognitoUserTokenCredentials,
  GetUserLoginCredentials,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from "../../../../components/UserCredentials";
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from "../../../../components/CognitoUserPoolSignin";
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from "../../../../components/Login";
import FindingCards from "../../SecurityAdvisors/FindingCards/FindingCards";
import FindingPieChart from "../../SecurityAdvisors/FindingsPieChart/FindingsPieChard";
import "../../../../styles/ComplianceDashboard.css";
import Cards from "../../SecurityAdvisors/Cards/Cards";

const { Option } = Select;

const Dashboard = ({
  onFindingsClick,
  selectedCompliance,
  setSelectedCompliance,
  filters,
}) => {
  const [options, setOptions] = useState([]);
  const [complianceSummary, setComplianceSummary] = useState([]);
  const [scanHistory, setScanHistory] = useState([]);
  const [showDashboard, setShowDashboard] = useState(false);
  const [selectedScanId, setSelectedScanId] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp.replace(" ", "T"));
    return new Intl.DateTimeFormat("en-US", {
      dateStyle: "medium",
      timeStyle: "short",
    }).format(date);
  };

  const handleCardClick = (history) => {
    setSelectedScanId(history.scan_id);
    setSelectedAccountId(history.account_id);
    setShowDashboard(true);
  };

  useEffect(() => {
    if (selectedScanId && selectedAccountId) {
      console.log("Selected Scan ID:", selectedScanId);
      console.log("Selected Account ID:", selectedAccountId);
      complianceData();
    }
  }, [selectedScanId, selectedAccountId]);

  const handleSelectChange = (value) => {
    setSelectedCompliance(value);
  };

  const handleBackButtonClick = () => {
    setSelectedCompliance(null);
    setSelectedScanId(null);
    setSelectedAccountId(null);
    setComplianceSummary([]);
    setShowDashboard(false);
  };

  const handleRenderFindingsList = (status) => {
    console.log("status", status);
    console.log("selected data before navigation:", selectedData);
    if (onFindingsClick) {
      onFindingsClick(status, selectedData, selectedScanId);
    }
  };

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    let authProviderTypeStr = "";

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    }
    if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
    }

    let credJson = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
      authProviderType: authProviderTypeStr,
    };

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      credJson.cognito_user_pool_email_password_info =
        GetCognitoUserTokenCredentials();
    }

    return credJson;
  };

  const getSecurityScanHistoryPayload = () => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "get_security_scan_history",
      command_args: {
        customerAwsIdList: [filters["AWS Account"]],
        AccountId: filters["AWS Account"],
        ScanStartTimeStamp: filters.ScanStartTimeStamp,
        EndStartTimeStamp: filters.EndStartTimeStamp,
      },
    };
  };

  const handleGetSecurityScanHistory = async (event) => {
    const url = GetSecurityScanUrl();
    const payload = getSecurityScanHistoryPayload();

    try {
      const resp = await axios.post(url, payload);
      if (resp.status === 200) {
        setScanHistory(resp.data.scan_history || []);
        console.log(resp.data);
      }
      return resp;
    } catch (err) {
      console.log("handleStartSecurityScan to " + url + " failed");
    }
  };

  const getComplianceScanSummaryPayload = (scanId, accountId) => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "get_compliance_scan_summary",
      command_args: {
        AccountId: accountId,
        ExpectedScanId: scanId,
      },
    };
  };

  const getComplianceScanSummary = async (scanId, accountId) => {
    const url = GetSecurityScanUrl();
    const payload = getComplianceScanSummaryPayload(scanId, accountId);
    console.log("scan summary payload", payload);
    try {
      const resp = await axios.post(url, payload);
      if (resp.status === 200) {
        console.log("Compliance Scan Summary", resp.data);
        return resp.data;
      }
      return { compliance_summary: [] };
    } catch (err) {
      console.log("getComplianceScanSummary to " + url + " failed");
      return { compliance_summary: [] };
    }
  };

  const complianceData = async () => {
    try {
      const resp = await getComplianceScanSummary(
        selectedScanId,
        selectedAccountId
      );
      const complianceSummary = resp.compliance_summary;
      const complianceTypes = complianceSummary.map((item) => {
        const type = item.compliance_type
          .replace(/\.[^/.]+$/, "")
          .replace(/_/g, " ")
          .toLowerCase();
        const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
        return { label: capitalizedType, value: item.compliance_type };
      });
      console.log({ complianceTypes });
      setOptions(complianceTypes);
      setComplianceSummary(complianceSummary);
      setShowDashboard(true);
    } catch (error) {
      console.error("Error fetching compliance data:", error);
    }
  };

  useEffect(() => {
    handleGetSecurityScanHistory();
  }, [filters]);

  const selectedData = complianceSummary.find(
    (item) => item.compliance_type === selectedCompliance
  );

  const findingCardsData = selectedData
    ? [
      {
        title: "Passed",
        content: selectedData.passed || 0,
        status: "PASS",
        backgroundColor: "#469485",
      },
      {
        title: "Failed",
        content: selectedData.failed || 0,
        status: "FAIL",
        backgroundColor: "#a94839",
      },
      {
        title: "Manual",
        content: selectedData.manual || 0,
        status: "MANUAL",
        backgroundColor: "#5b7ea1",
      },
      {
        title: "Total",
        content: selectedData.total_tests || 0,
        status: "TOTAL",
        backgroundColor: "#5454a1",
      },
    ]
    : [];

  const pieChartsData = selectedData
    ? [
      {
        series: [
          selectedData.passed || 0,
          selectedData.failed || 0,
          selectedData.manual || 0,
          // selectedData.total_tests || 0,
        ],
        labels: ["Passed", "Failed", "Manual" /*, "Total Tests" */],
        title: "Overall Status by Result",
        colors: ["#9d4a88", "#ca6281", "#a24965" /*"#8c50ac"*/],
      },
    ]
    : [];

  return (
    <>
      {showDashboard ? (
        <div className="dashboard">
          <div className="dashboard-dropdown">
            <Select
              placeholder="Select a compliance type"
              onChange={handleSelectChange}
              value={selectedCompliance}
              className="dashboard-select"
              dropdownStyle={{ textTransform: "uppercase" }}
            >
              {options.map((option, index) => (
                <Option key={index} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>

          {selectedData && findingCardsData.length > 0 && (
            <div className="dash">
              <Button
                className="back-btn"
                onClick={handleBackButtonClick}
                icon={<ArrowLeftOutlined />}
              >
                Back
              </Button>
              <Row gutter={16}>
                <Col span={12}>
                  <Row gutter={[16, 16]}>
                    {findingCardsData.map((card, index) => (
                      <Col span={12} key={index}>
                        <div className="finding-cards">
                          <FindingCards
                            title={card.title}
                            content={card.content}
                            backgroundColor={card.backgroundColor}
                            onClick={card.status == "TOTAL" ? null : () =>
                              handleRenderFindingsList(card.status)
                            }
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Col span={12}>
                  <div className="dashboard-piechart">
                    {pieChartsData.map((data, index) => (
                      <FindingPieChart
                        key={index}
                        series={data.series}
                        labels={data.labels}
                        title={data.title}
                        colors={data.colors}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      ) : (
        <div className="scan-history">
          {scanHistory.map((history, index) => (
            <Cards
              key={index}
              title={formatTimestamp(history.scan_timestamp)}
              content={history.scan_id}
              button="View"
              onClick={() => handleCardClick(history)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Dashboard;
