import React from "react";
import { Table } from "antd";
import "../../../../styles/DataTable.css";

const getStatusColor = (status) => {
  switch (status) {
    case "PASS":
      return "green";
    case "FAIL":
      return "red";
    case "MANUAL":
      return "darkblue";
    default:
      return "black";
  }
};

const getSeverityColor = (severity) => {
  switch (severity) {
    case "Low":
      return "blue";
    case "Medium":
      return "brown";
    case "High":
      return "red";
    case "Critical":
      return "lightcoral";
    default:
      return "black";
  }
};

const columnConfig = {
  commonColumns: [
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      className: "custom-header",
    },
    {
      title: "Resource Name",
      dataIndex: "resource_name",
      key: "resourceName",
      className: "custom-header",
    },
    {
      title: "Resource ID",
      dataIndex: "resource_id",
      key: "resourceId",
      className: "custom-header",
    },
  ],
  complianceColumns: {
    "gdpr_aws": [
      {
        title: "Section",
        dataIndex: "requirements_attributes_section",
        key: "requirements_attributes_section",
        className: "custom-header",
      },
      {
        title: "Id",
        dataIndex: "requirements_id",
        key: "requirements_id",
        className: "custom-header",
      },
      {
        title: "Status",
        dataIndex: "status_extended",
        key: "status_extended",
        className: "custom-header",
      },
    ],
    "soc2_aws": [
      {
        title: "Section",
        dataIndex: "requirements_attributes_section",
        key: "requirements_attributes_section",
        className: "custom-header",
      },
      {
        title: "Id",
        dataIndex: "requirements_id",
        key: "requirements_id",
        className: "custom-header",
      },
      {
        title: "Status",
        dataIndex: "status_extended",
        key: "status_extended",
        className: "custom-header",
      },
    ],
    "iso27001_2013_aws": [
      {
        title: "Category",
        dataIndex: "requirements_attributes_category",
        key: "requirements_attributes_category",
        className: "custom-header",
      },
      {
        title: "Objective Id",
        dataIndex: "requirements_attributes_objetive_id",
        key: "requirements_attributes_objetive_id",
        className: "custom-header",
      },
      {
        title: "Summary",
        dataIndex: "requirements_attributes_check_summary",
        key: "requirements_attributes_check_summary",
        className: "custom-header",
      },
      {
        title: "Status",
        dataIndex: "status_extended",
        key: "status_extended",
        className: "custom-header",
      },
      {
        title: "Objective Name",
        dataIndex: "requirements_attributes_objetive_name",
        key: "requirements_attributes_objetive_name",
        className: "custom-header",
      },
    ],
    "aws_well_architected_framework_security_pillar_aws.csv": [
      {
        title: "Assessment Method",
        dataIndex: "requirements_attributes_assessment_method",
        key: "requirements_attributes_assessment_method",
        className: "custom-header",
      },
      {
        title: "Implementation URL",
        dataIndex: "requirements_attributes_implementation_guidance_url",
        key: "requirements_attributes_implementation_guidance_url",
        className: "custom-header",
      },
      {
        title: "Name",
        dataIndex: "requirements_attributes_name",
        key: "requirements_attributes_name",
        className: "custom-header",
      },
      {
        title: "Section",
        dataIndex: "requirements_attributes_section",
        key: "requirements_attributes_section",
        className: "custom-header",
      },
      {
        title: "Sub Section",
        dataIndex: "requirements_attributes_subsection",
        key: "requirements_attributes_subsection",
        className: "custom-header",
      },
      {
        title: "Practice Id",
        dataIndex: "requirements_attributes_well_architected_practice_id",
        key: "requirements_attributes_well_architected_practice_id",
        className: "custom-header",
      },
      {
        title: "Question Id",
        dataIndex: "requirements_attributes_well_architected_question_id",
        key: "requirements_attributes_well_architected_question_id",
        className: "custom-header",
      },
      {
        title: "Id",
        dataIndex: "requirements_id",
        key: "requirements_id",
        className: "custom-header",
      },
      {
        title: "Status",
        dataIndex: "status_extended",
        key: "status_extended",
        className: "custom-header",
      },
      {
        title: "Severity",
        dataIndex: "requirements_attributes_level_of_risk",
        key: "severity",
        className: "custom-header",
        render: (text) => (
          <span
            className={`severity-text ${text.toLowerCase()}`}
            style={{ color: getSeverityColor(text) }}
          >
            {text}
          </span>
        ),
      },
    ],
    "aws_well_architected_framework_reliability_pillar_aws.csv": [
      {
        title: "Assessment Method",
        dataIndex: "requirements_attributes_assessment_method",
        key: "requirements_attributes_assessment_method",
        className: "custom-header",
      },
      {
        title: "Implementation URL",
        dataIndex: "requirements_attributes_implementation_guidance_url",
        key: "requirements_attributes_implementation_guidance_url",
        className: "custom-header",
      },
      {
        title: "Name",
        dataIndex: "requirements_attributes_name",
        key: "requirements_attributes_name",
        className: "custom-header",
      },
      {
        title: "Section",
        dataIndex: "requirements_attributes_section",
        key: "requirements_attributes_section",
        className: "custom-header",
      },
      {
        title: "Sub Section",
        dataIndex: "requirements_attributes_subsection",
        key: "requirements_attributes_subsection",
        className: "custom-header",
      },
      {
        title: "Practice Id",
        dataIndex: "requirements_attributes_well_architected_practice_id",
        key: "requirements_attributes_well_architected_practice_id",
        className: "custom-header",
      },
      {
        title: "Question Id",
        dataIndex: "requirements_attributes_well_architected_question_id",
        key: "requirements_attributes_well_architected_question_id",
        className: "custom-header",
      },
      {
        title: "Id",
        dataIndex: "requirements_id",
        key: "requirements_id",
        className: "custom-header",
      },
      {
        title: "Status",
        dataIndex: "status_extended",
        key: "status_extended",
        className: "custom-header",
      },
      {
        title: "Severity",
        dataIndex: "requirements_attributes_level_of_risk",
        key: "severity",
        className: "custom-header",
        render: (text) => (
          <span
            className={`severity-text ${text.toLowerCase()}`}
            style={{ color: getSeverityColor(text) }}
          >
            {text}
          </span>
        ),
      },
    ],
    "aws_foundational_technical_review_aws.csv": [
      {
        title: "Section",
        dataIndex: "requirements_attributes_section",
        key: "requirements_attributes_section",
        className: "custom-header",
      },
      {
        title: "Type",
        dataIndex: "requirements_attributes_type",
        key: "requirements_attributes_type",
        className: "custom-header",
      },
      {
        title: "Id",
        dataIndex: "requirements_id",
        key: "requirements_id",
        className: "custom-header",
      },
      {
        title: "Status",
        dataIndex: "status_extended",
        key: "status_extended",
        className: "custom-header",
      },
    ],
    "aws_foundational_security_best_practices_aws.csv": [
      {
        title: "Section",
        dataIndex: "requirements_attributes_section",
        key: "requirements_attributes_section",
        className: "custom-header",
      },
      {
        title: "Service",
        dataIndex: "requirements_attributes_service",
        key: "requirements_attributes_service",
        className: "custom-header",
      },
      {
        title: "Id",
        dataIndex: "requirements_id",
        key: "requirements_id",
        className: "custom-header",
      },
      {
        title: "Description",
        dataIndex: "requirements_description",
        key: "requirements_description",
        className: "custom-header",
      },
      {
        title: "Status",
        dataIndex: "status_extended",
        key: "status_extended",
        className: "custom-header",
      },
    ],
    "aws_audit_manager_control_tower_guardrails_aws.csv": [
      {
        title: "Section",
        dataIndex: "requirements_attributes_section",
        key: "requirements_attributes_section",
        className: "custom-header",
      },
      {
        title: "Service",
        dataIndex: "requirements_attributes_service",
        key: "requirements_attributes_service",
        className: "custom-header",
      },
      {
        title: "Id",
        dataIndex: "requirements_id",
        key: "requirements_id",
        className: "custom-header",
      },
      {
        title: "Description",
        dataIndex: "requirements_description",
        key: "requirements_description",
        className: "custom-header",
      },
      {
        title: "Status",
        dataIndex: "status_extended",
        key: "status_extended",
        className: "custom-header",
      },
    ],
    "aws_account_security_onboarding_aws.csv": [
      {
        title: "Section",
        dataIndex: "requirements_attributes_section",
        key: "requirements_attributes_section",
        className: "custom-header",
      },
      {
        title: "Service",
        dataIndex: "requirements_attributes_service",
        key: "requirements_attributes_service",
        className: "custom-header",
      },
      {
        title: "Id",
        dataIndex: "requirements_id",
        key: "requirements_id",
        className: "custom-header",
      },
      {
        title: "Description",
        dataIndex: "requirements_description",
        key: "requirements_description",
        className: "custom-header",
      }
    ],
  }
};


function getColumnsByComplianceType(columnType, pagination) {
  const commonColumns = columnConfig.commonColumns;
  const specificColumns = columnConfig.complianceColumns[columnType] || [];
  return [
    {
      title: 'S.No',
      key: 'index',
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1, // Adjust for current page
    },
    ...specificColumns,
    ...commonColumns];
}

const DataTable = ({ data, loading, title, columnType, pagination, onChange }) => {
  return (
    <div className="table-container">
      <Table
        dataSource={data}
        columns={getColumnsByComplianceType(columnType, pagination)}
        pagination={pagination}
        className="custom-table"
        loading={loading}
        title={title}
        onChange={onChange}
      />
    </div>
  );
};

export default DataTable;
