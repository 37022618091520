import React, { useEffect, useContext, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import {
  GetCognitoUserTokenCredentials,
  GetUserLoginCredentials,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from "../UserCredentials";
import {
  GetAckSetUrl,
  GetCreateFreshResourcesInfoForUserUrl,
  GetRegionsForAwsKeysUrl,
  GetIamPolicyDownloadUrl,
  GetSecurityScanUrl,
} from "../../routes";
import axios from "axios";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from "../CognitoUserPoolSignin";
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from "../Login";
import Select, { components } from "react-select";
import arrowDownIcon from "../../images/arrow-down.png";
import { customDropdownStyles } from "../CostManager/styleUtils";
import { uniqueId } from "lodash";
import CopyIcon from "../CopyIcon/CopyIcon";
import { Button as AntButton, Table, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import AwsAccountSetupAndVerifier from "./AwsAccountSetupAndVerifier";
import "./AwsAccessKeys.css";
import { ConfirmationModalContext } from "../../context/ConfirmationModalContext";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowDownIcon} />
    </components.DropdownIndicator>
  );
};

function AwsAccessKeys() {
  const [keys, setKeys] = useState([]);
  const [regions, setRegions] = useState([]);
  const { openModal } = useContext(ConfirmationModalContext);

  const getAccessKeyDataRead = () => {
    currKey["data_op"] = "readWithRbac";
    return currKey;
  };

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    let authProviderTypeStr = "";

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    }
    if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
    }

    let credJson = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
      authProviderType: authProviderTypeStr,
    };

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      credJson.cognito_user_pool_email_password_info =
        GetCognitoUserTokenCredentials();
    }

    return credJson;
  };

  const getAwsKeysReadPayload = () => {
    let keysReadPayload = {
      concerto_user_credentials: GetLoginCredentials(),
      ack_data: getAccessKeyDataRead(),
    };

    return keysReadPayload;
  };

  const fetchRegionsForAwsKeys = async () => {
    let userCreds = GetUserLoginCredentials()[0];

    let userCredentials = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
    };

    const url = GetRegionsForAwsKeysUrl();
    const payload = getPayload(userCredentials);

    try {
      const resp = await axios.post(url, payload);
      if (
        resp.status != 200 ||
        resp?.data?.message === "Internal server error"
      ) {
        return;
      }
      setRegions(resp.data.aws_regions_list);
      return resp.data.aws_regions_list;
    } catch (err) {
      console.log("fetchDataForSelection to " + url + " failed");
    }
  };

  const getAwsKeys = async () => {
    setKeys([]);

    // console.log("+++++++++= getawskeys function ")
    const url = GetAckSetUrl();
    const payload = getAwsKeysReadPayload();

    // console.log("####################  getAwsKeysReadPayload()   #####3232########################")
    // console.log(url)
    // console.log(payload)

    try {
      const resp = await axios.post(url, payload);
      console.log({ rd: resp.data });
      let respData = [];
      Object.keys(resp.data).map((key, index) => {
        respData[index] = resp.data[key];
      });
      console.log({ respData });

      setKeys(respData);

      return resp;
    } catch (err) {
      console.log("getAwsKeys to " + url + " failed.");
    }
  };

  useEffect(() => {
    // setSelectedRegion(regionList[0])
    getAwsKeys();
    fetchRegionsForAwsKeys();
  }, []);

  const [currKey, setAddKey] = useState({
    name: "",
    AWS_ACCOUNT_ID: "",
    AWS_ACCESS_KEY_ID: "",
    AWS_SECRET_ACCESS_KEY: "",
    DEFAULT_REGION: "",
    REGIONS: [],
  });

  // Modal handling
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isAddMode, setIsAddMode] = useState(false);

  const handleEdit = (record) => {
    setAddKey({
      name: record.FriendlyAlias,
      AWS_ACCOUNT_ID: record.AccountId,
      AWS_ACCESS_KEY_ID: record.AWS_ACCESS_KEY_ID,
      AWS_SECRET_ACCESS_KEY: record.AWS_SECRET_ACCESS_KEY,
      DEFAULT_REGION: record.DefaultRegion,
      REGIONS: record.Regions,
      Description: record.description,
    });
    setIsAddMode(false);
    handleShow();
  };

  const handleDelete = (record, isVerified) => {
    const confirmDelete = () => {
      const getDeleteAccessKeyData = () => {
        let accountTypeStr =
          isVerified == true ? "VerifiedAccount" : "UnverifiedAccount";

        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
        // console.log(accountTypeStr)
        // return

        const data = {
          name: record.FriendlyAlias,
          AWS_ACCOUNT_ID: record.AccountId,
          AWS_ACCESS_KEY_ID: record.AWS_ACCESS_KEY_ID,
          AWS_SECRET_ACCESS_KEY: record.AWS_SECRET_ACCESS_KEY,
          DEFAULT_REGION: record.DefaultRegion,
          REGIONS: record.Regions,
          Description: record.description,
          data_op: "delete",
          account_type: accountTypeStr,
        };
        return data;
      };

      const getDeletePayload = () => {
        return {
          concerto_user_credentials: GetLoginCredentials(),
          ack_data: getDeleteAccessKeyData(),
        };
      };

      const submitDelete = async (event) => {
        const url = GetAckSetUrl();
        const payload = getDeletePayload();

        try {
          const resp = await axios.post(url, payload);
          console.log(resp);
          if (resp.status === 200) {
            getAwsKeys();
          }
          return resp;
        } catch (err) {
          console.log("submitDelete to " + url + " failed");
        }
      };

      submitDelete();
    };
    openModal(
      `Are you sure you want to delete the access key for ${record.FriendlyAlias}?`,
      confirmDelete,
      { title: "Delete Confirmation" }
    );
  };

  const handleAdd = () => {
    setAddKey({
      name: "",
      AWS_ACCOUNT_ID: "",
      AWS_ACCESS_KEY_ID: "",
      AWS_SECRET_ACCESS_KEY: "",
      DEFAULT_REGION: "",
      REGIONS: [],
    });
    setIsAddMode(true);
    handleShow();
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setAddKey({
      ...currKey,
      [evt.target.name]: value,
    });
    //console.log("currKey", currKey);
  };

  const getAccessKeyData = () => {
    currKey["data_op"] = "update";

    if (isAddMode) {
      currKey["data_op"] = "create";
    }

    return currKey;
  };

  const getPayload = () => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      ack_data: getAccessKeyData(),
    };
  };

  const handleAwsUserDataRefresh = async (event) => {
    // event.preventDefault();
    const url = GetCreateFreshResourcesInfoForUserUrl();

    console.log("################### handleAwsUserDataRefresh >>>>>> ");
    console.log(url);

    if (!keys) return <div></div>;

    if (!keys.length) return <div></div>;

    const processedAccounts = keys
      ?.map((key) => key?.AccountId)
      .filter(Boolean);

    let payload = {
      concerto_user_credentials: GetLoginCredentials(),
      account_list: processedAccounts,
    };

    try {
      const resp = await axios.post(url, payload);
      if (resp.status === 200) {
        return toast.success(
          "Data refresh initiated! Please check back in 5 minutes"
        );
      }
    } catch (err) {
      console.log("handleAwsUserDataRefresh to " + url + " failed");
    }
  };

  // UPDATE / ADD
  const submitKey = async (event) => {
    // event.preventDefault();
    const url = GetAckSetUrl();

    console.log("################### onAckSetDataSaveRequest >>>>>> ");
    console.log(url);
    console.log("getPayload()", getPayload());
    try {
      const resp = await axios.post(url, getPayload());
      console.log(resp);
      if (resp.status === 200) {
        handleClose();
        getAwsKeys();
      }
      //   return resp
    } catch (err) {
      console.log("onAckSetDataSaveRequest to " + url + " failed");
    }
  };

  const onDefaultRegionSelected = (event) => {
    console.log("onDefaultRegionSelected ==> event", event);
    setDefaultAwsRegion(event);

    setAddKey({
      ...currKey,
      DEFAULT_REGION: event,
    });
  };

  let defaultAwsRegion = "ap-south-1";

  const getDefaultAwsRegion = () => {
    if (currKey.DEFAULT_REGION === "") {
      return "Select Region";
    }
    return currKey.DEFAULT_REGION;
  };
  const setDefaultAwsRegion = (defaultRegion) => {
    defaultAwsRegion = defaultRegion;
  };

  const onRegionSelected = (options) => {
    setAddKey({
      ...currKey,
      REGIONS: options.map((i) => i.RegionCode) || [],
    });
  };

  const getRegionsDropDown = () => {
    return (
      <div className="inline-block">
        <div className="m-b-5 fs-14">Regions</div>
        <Select
          getOptionLabel={(option) =>
            option.RegionName + " " + option.RegionCode
          }
          getOptionValue={(option) => option.RegionCode}
          onChange={(e) => {
            onRegionSelected(e);
          }}
          options={regions}
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          name="Regions_Aws_Keys"
          placeholder="Select"
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customDropdownStyles}
        />
      </div>
    );
  };

  console.log({ keys });

  const getVerifiedKeysElements = () => {
    if (!keys || !keys.length) return <div></div>;

    const verifiedAccounts = Object.values(keys).filter(
      (key) => key.AccountId && key.IsVerified
    );

    const columns = [
      {
        title: "Account ID",
        dataIndex: "AccountId",
        key: "AccountId",
        render: (text) => (
          <div className="account-id-col">
            <img
              style={{ height: "2.3vw" }}
              src="https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png"
              alt=""
            />
            <CopyIcon text={text} title={"Copy Account ID"} />
          </div>
        ),
      },
      {
        title: "Friendly Name",
        dataIndex: "FriendlyAlias",
        key: "FriendlyAlias",
        render: (text) => <CopyIcon text={text} title={"Copy Name"} />,
      },
      {
        title: "Description",
        dataIndex: "Description",
        key: "Description",
      },
      {
        title: "Default Region",
        dataIndex: "DefaultRegion",
        key: "DefaultRegion",
      },
      {
        title: "Is Root",
        dataIndex: "IsRoot",
        key: "IsRoot",
        render: (isRoot) => (isRoot ? "Yes" : "No"),
      },
      {
        title: "Status",
        dataIndex: "IsVerified",
        key: "Status",
        render: (isVerified) => (isVerified ? "Verified" : "Pending"),
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div className="action-buttons">
            {record.IsVerified ? (
              <>
                <AntButton
                  className="action-btn edit-btn"
                  onClick={() => handleEdit(record)}
                  ghost
                  shape="round"
                >
                  <p>Edit</p>
                  <span className="edit-icon-container">
                    <EditOutlined className="button-icon" />
                  </span>
                </AntButton>
                <AntButton
                  className="action-btn delete-btn"
                  onClick={() => handleDelete(record)}
                  ghost
                  shape="round"
                >
                  <p> Delete</p>
                  <span className="delete-icon-container">
                    <DeleteOutlined className="button-icon" />
                  </span>
                </AntButton>
              </>
            ) : (
              <>
                <AntButton
                  className="action-btn verify-btn"
                  onClick={() => handleVerify(record)}
                  ghost
                  shape="round"
                >
                  <p>Verify</p>
                  <span className="verify-icon-container">
                    <CheckOutlined className="button-icon" />
                  </span>
                </AntButton>
                <AntButton
                  className="action-btn delete-btn"
                  onClick={() => handleDelete(record)}
                  ghost
                  shape="round"
                >
                  <p> Delete</p>
                  <span className="delete-icon-container">
                    <DeleteOutlined className="button-icon" />
                  </span>
                </AntButton>
              </>
            )}
          </div>
        ),
      },
    ];
    return (
      <div className="aws-table-container">
        <Table
          font
          size="middle"
          pagination={false}
          dataSource={verifiedAccounts}
          columns={columns}
          className="aws-table"
        />
      </div>
    );
  };

  const getUnverifiedAccountsCount = () => {
    if (!keys || typeof keys !== "object") return 0;

    return Object.values(keys).filter((key) => key.AccountId && !key.IsVerified)
      .length;
  };

  const getUnverifiedKeysElements = () => {
    console.log("count", getUnverifiedAccountsCount);
    if (!keys || !keys.length) return <div></div>;

    const unverifiedAccounts = Object.values(keys).filter(
      (key) => key.AccountId && !key.IsVerified
    );

    const columns = [
      {
        title: "Account ID",
        dataIndex: "AccountId",
        key: "AccountId",
        render: (text) => (
          <div style={{ display: "flex", gap: "0.5vw", alignItems: "center" }}>
            <img
              style={{ height: "2.3vw" }}
              src="https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png"
              alt=""
            />{" "}
            <CopyIcon text={text} title={"Copy Account ID"} />
          </div>
        ),
      },
      {
        title: "Friendly Name",
        dataIndex: "FriendlyAlias",
        key: "FriendlyAlias",
        render: (text) => <CopyIcon text={text} title={"Copy Name"} />,
      },
      {
        title: "Description",
        dataIndex: "Description",
        key: "Description",
      },
      {
        title: "Default Region",
        dataIndex: "DefaultRegion",
        key: "DefaultRegion",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div
            style={{ display: "flex", gap: "0.4vw", justifyContent: "center" }}
          >
            <AntButton
              className="action-btn verify-btn"
              onClick={() => handleVerify(record)}
              ghost
              shape="round"
            >
              <p>Verify</p>
              <span className="verify-icon-container">
                <CheckOutlined className="button-icon" />
              </span>
            </AntButton>
            <AntButton
              className="action-btn delete-btn"
              onClick={() => handleDelete(record)}
              ghost
              shape="round"
            >
              <p> Delete</p>
              <span className="delete-icon-container">
                <DeleteOutlined className="button-icon" />
              </span>
            </AntButton>
          </div>
        ),
      },
    ];

    return (
      <Table
        font
        size="middle"
        pagination={false}
        dataSource={unverifiedAccounts}
        columns={columns}
        className="aws-table"
      />
    );
  };

  const [verifyingAccount, setVerifyingAccount] = useState(null);

  const handleVerify = (record) => {
    setVerifyingAccount(record);
  };

  const handleVerified = async (account) => {
    // Implement the verification logic here
    console.log("Verifying account:", account);
    // You might want to call an API to verify the account
    try {
      // Replace with actual API call to verify the account
      await axios.post(`/api/verify-account/${account.AccountId}`);
      toast.success("Account verified successfully");
      getAwsKeys(); // Refresh the table
    } catch (error) {
      console.error("Failed to verify account:", error);
      toast.error("Failed to verify account");
    }
  };

  return (
    <div className="access-key-container">
      <div className="addKey">
        <div className="access-key-title">
          <div className="aws-title">AWS Accounts</div>
          <div className="aws-btns">
            <button className="btn add-btn" onClick={handleAdd}>
              + ADD NEW
            </button>

            <Tooltip title="Read latest AWS resource info data">
              <button
                className="btn refresh-btn"
                onClick={handleAwsUserDataRefresh}
              >
                <i className="bi bi-arrow-clockwise"></i> REFRESH AWS DATA
              </button>
            </Tooltip>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>AWS Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="add-key-form">
              <Form>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="name"
                    value={currKey.name}
                    onChange={handleChange}
                    placeholder="Enter Name"
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    type="text"
                    name="AWS_ACCOUNT_ID"
                    value={currKey.AWS_ACCOUNT_ID}
                    onChange={handleChange}
                    placeholder="Enter Account Id"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    type="textarea"
                    name="Description"
                    value={currKey.Description}
                    onChange={handleChange}
                    placeholder="Enter Description"
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>

                <br />
                <div className="m-b-5">{getRegionsDropDown()}</div>
                <p>Default Region</p>
                <DropdownButton
                  alignRight
                  title={getDefaultAwsRegion()}
                  id="dropdown-menu-align-right"
                  onSelect={onDefaultRegionSelected}
                  defaultValue={getDefaultAwsRegion()}
                >
                  {regionList.map((region, index) => (
                    <Dropdown.Item eventKey={region.code} key={uniqueId()}>
                      {" "}
                      {region.name}{" "}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Button
                  style={{ float: "right" }}
                  variant="primary"
                  onClick={submitKey}
                >
                  {isAddMode === true ? "Add" : "Edit"}
                </Button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="aws-access-keys securityViolationsTable">
        <h5>Verified AWS Accounts</h5>
        {getVerifiedKeysElements()}
      </div>
      <div className="aws-access-keys securityViolationsTable">
        {getUnverifiedAccountsCount() > 0 && (
          <div className="aws-access-keys securityViolationsTable">
            <h5>Unverified AWS Accounts</h5>
            {getUnverifiedKeysElements()}
          </div>
        )}
      </div>
      {verifyingAccount && (
        <AwsAccountSetupAndVerifier
          account={verifyingAccount}
          onClose={() => setVerifyingAccount(null)}
          onVerified={handleVerified}
        />
      )}
    </div>
  );
}

const regionList = [
  {
    code: "us-east-2",
    name: "US East (Ohio)",
  },
  {
    code: "us-east-1",
    name: "US East (N. Virginia)",
  },
  {
    code: "us-west-1",
    name: "US West (N. California)",
  },
  {
    code: "us-west-2",
    name: "US West (Oregon)",
  },
  {
    code: "af-south-1",
    name: "Africa (Cape Town)",
  },
  {
    code: "ap-east-1",
    name: "Asia Pacific (Hong Kong)",
  },
  {
    code: "ap-southeast-3",
    name: "Asia Pacific (Jakarta)",
  },
  {
    code: "ap-south-1",
    name: "Asia Pacific (Mumbai)",
  },
  {
    code: "ap-northeast-3",
    name: "Asia Pacific (Osaka)",
  },
  {
    code: "ap-northeast-2",
    name: "Asia Pacific (Seoul)",
  },
  {
    code: "ap-southeast-1",
    name: "Asia Pacific (Singapore)",
  },
  {
    code: "ap-southeast-2",
    name: "Asia Pacific (Sydney)",
  },
  {
    code: "ap-northeast-1",
    name: "Asia Pacific (Tokyo)",
  },
  {
    code: "ca-central-1",
    name: "Canada (Central)",
  },
  {
    code: "eu-central-1",
    name: "Europe (Frankfurt)",
  },
  {
    code: "eu-west-1",
    name: "Europe (Ireland)",
  },
  {
    code: "eu-west-2",
    name: "Europe (London)",
  },
  {
    code: "eu-south-1",
    name: "Europe (Milan)",
  },
  {
    code: "eu-west-3",
    name: "Europe (Paris)",
  },
  {
    code: "eu-north-1",
    name: "Europe (Stockholm)",
  },
  {
    code: "me-south-1",
    name: "Middle East (Bahrain)",
  },
  {
    code: "sa-east-1	South",
    name: "America (São Paulo)",
  },
];

export default AwsAccessKeys;
