import {
  CREATE_NEW_AWS_API_GATEWAY,
  GET_RESOURCES_OVERVIEW_UI_CACHE_API,
  GET_RESOURCES_OVERVIEW_UI_UI_CACHE_API,
  POST_ACK_SET_API,
  POST_MY_PROFILE_API,
  SYNC_FROM_CLOUD_API,
  SYNC_FROM_CLOUD_API_V2,
  TERRAFORM_SCRIPTS_DOWNLOAD_API,
  VERIFY_USER_LOGIN_API,
  GET_COST_MANAGER_API,
  GET_SIMULATED_COST_MANAGER_API,
  GET_ALL_ACCOUNTS_API,
  ADVANCED_SEARCH_API,
  GET_COST_PLANNER_INFO_API,
  GET_RESOURCE_PARAMS_OPTIONS_API,
  GET_EC2_INSTANCE_COST_DETAILS_API,
  GET_SECURITY_VIOLATIONS_LIST_API,
  GET_SECURITY_VIOLATIONS_TYPE_DEFINITION_API,
  VERIFY_COGNITO_USER_POOL_LOGIN_API,
  CONFIRM_REGISTERED_USER_API,
  GET_REGIONS_FOR_AWS_KEYS,
  GET_LATEST_RESOURCES_INFO,
  SET_RESOURCE_PARAMS,
  COGNITO_USER_POOL_GET_RESET_CODE,
  GET_ASSETS_INFO_LIST,
  GET_ASSET_INFO_FOR_RESOURCE,
  GET_ASSET_INFO,
  GET_RESOURCES_DIFF_INFO,
  GET_SCHEDULED_EVENTS,
  VERIFY_CAPTCHA,
  GET_EC2_SCHEDULE_INFO_AND_RECOMMENDATIONS,
  SET_EC2_RECO_APPROVAL,
  CONTACT_US_FORM,
  GET_AI_GEN_RESPONSE,
  SET_EC2_INSTANCE_STATE,
  CREATE_FRESH_RESOURCES_INFO_FOR_USER,
  GET_CARLO_EP,
  GET_EXPLAIN_AI_RECOS,
  HANDLE_AI_PARAMS, GET_IAM_POLICY_FOR_DOWNLOAD_API,
  GET_SECURITY_SCAN_REQUEST_API, GET_COST_MANAGER_API_V2,
  GET_AI_GEN_QUERY
} from './routeConst';
import axios from 'axios';

const HTTPS_PROTOCOL = 'https://';
const WEBSOCKET_PROTOCOL = 'wss://';
const PROD_DEPLOYMENT_MODE = 'prod';
const DEV_DEPLOYMENT_MODE = 'dev';

export const GetDeploymentMode = () => {
  return process.env.REACT_APP_DEPLOYMENT_MODE;
};

export const IsDeploymentModeProd = () => {
  return GetDeploymentMode() == PROD_DEPLOYMENT_MODE;
};

export const IsDeploymentModeDev = () => {
  return GetDeploymentMode() == DEV_DEPLOYMENT_MODE;
};

export const IsDemoModeHideAccountDetails = () => {
  return process.env.REACT_APP_DEMO_MODE_HIDE_ACCOUNT_DETAILS;
};

const BFF_BASE_URL = 'concertopybff.hammerheadware.com';
const GO_BFF_BASE_URL = 'concertogobff.hammerheadware.com';

const DEV_GO_BFF_BASE_URL = '93k3ooi2ue.execute-api.ap-south-1.amazonaws.com/dev'; // "concertogobff.hammerheadware.com"
const PROD_GO_BFF_BASE_URL = 'wx9nupmrvj.execute-api.ap-south-1.amazonaws.com/dev';

// const DEV_GO_BFF_BASE_URL = "93k3ooi2ue.execute-api.ap-south-1.amazonaws.com/dev"

const PROD_GO_RESOURCE_WATCHER_BASE_URL = 'rht816jky3.execute-api.ap-south-1.amazonaws.com/dev';
const DEV_GO_RESOURCE_WATCHER_BASE_URL = 'ap1a84vki0.execute-api.ap-south-1.amazonaws.com/dev';

// AWS Resource Icon Path
const PROD_AWS_RESOURCE_ICON_PATH = 'bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com';
const DEV_AWS_RESOURCE_ICON_PATH = 'bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com';

// BFF => backend for frontend
const getBffURL = () => {
  return BFF_BASE_URL;
};

const getBffHttpsURL = () => {
  return HTTPS_PROTOCOL + BFF_BASE_URL;
};

const getGoBffBaseUrl = () => {
  if (IsDeploymentModeProd()) {
    return PROD_GO_BFF_BASE_URL;
  } else if (IsDeploymentModeDev()) {
    return DEV_GO_BFF_BASE_URL;
  }
};

const getGoResourceWatcherBaseUrl = () => {
  if (IsDeploymentModeProd()) {
    return PROD_GO_RESOURCE_WATCHER_BASE_URL;
  } else if (IsDeploymentModeDev()) {
    return DEV_GO_RESOURCE_WATCHER_BASE_URL;
  }
};

const getGoResourceWatcherUrl = () => {
  return WEBSOCKET_PROTOCOL + getGoResourceWatcherBaseUrl();
};

const getGoBffHttpsUrl = () => {
  return HTTPS_PROTOCOL + getGoBffBaseUrl(); // GO_BFF_BASE_URL
};

const getResourceStagePrefix = () => {
  return '/dev/';
};

const getHttspsBFFResourceURL = (resource) => {
  return getBffHttpsURL() + getResourceStagePrefix() + resource;
};
export const GetCreateApiGwURL = () => {
  return getHttspsBFFResourceURL(CREATE_NEW_AWS_API_GATEWAY);
};

export const GetTerraformScriptsURL = () => {
  return getHttspsBFFResourceURL(TERRAFORM_SCRIPTS_DOWNLOAD_API);
};

export const GetSyncFromCloudURL = () => {
  return getHttspsBFFResourceURL(SYNC_FROM_CLOUD_API);
};

export const GetSyncFromCloudV2URL = () => {
  let url = getGoBffHttpsUrl() + '/' + SYNC_FROM_CLOUD_API_V2;
  return url;
};

export const GetResourcesOverviewUiCacheUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + GET_RESOURCES_OVERVIEW_UI_CACHE_API;
  return url;
};

/*
func GetLatestResourcesInfoEndPoint() string {
	return "/get_latest_resources_info"
}
 */

export const GetLatestResourcesInfoUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + GET_LATEST_RESOURCES_INFO;
  return url;
};

export const GetSetResourceParamsUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + SET_RESOURCE_PARAMS;
  return url;
};

export const GetMyProfileUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + POST_MY_PROFILE_API;
  return url;
};

export const GetAckSetUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + POST_ACK_SET_API;
  return url;
};

export const GetIamPolicyDownloadUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + GET_IAM_POLICY_FOR_DOWNLOAD_API;
  return url;
};

export const GetSecurityScanUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + GET_SECURITY_SCAN_REQUEST_API;
  return url;
};

export const GetVerifyUserLoginUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + VERIFY_USER_LOGIN_API;
  return url;
};

export const GetVerifyCognitoUserPoolLoginUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + VERIFY_COGNITO_USER_POOL_LOGIN_API;
  return url;
};

export const GetConfirmRegisteredUserUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + CONFIRM_REGISTERED_USER_API;
  return url;
};

export const GetCostManagerUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + GET_COST_MANAGER_API;
  return url;
};

export const GetCostManagerV2Url = () => {
  let url = getGoBffHttpsUrl() + '/' + GET_COST_MANAGER_API_V2;
  return url;
};

export const GetSimulatedCostManagerUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + GET_SIMULATED_COST_MANAGER_API;
  return url;
};

export const GetAllAccountsURL = () => {
  return getGoBffHttpsUrl() + '/' + GET_ALL_ACCOUNTS_API;
};

export const GetAdvancedSearchURL = () => {
  return getGoBffHttpsUrl() + '/' + ADVANCED_SEARCH_API;
};

export const getResourceWatcherUrl = () => {
  return getGoResourceWatcherUrl();
};

export const GetCostPlannerUrl = () => {
  return getGoBffHttpsUrl() + '/' + GET_COST_PLANNER_INFO_API;
};

export const GetCreateFreshResourcesInfoForUserUrl = () => {
  let url = getGoBffHttpsUrl() + '/' + CREATE_FRESH_RESOURCES_INFO_FOR_USER;
  return url;
};

export const GetEc2ParamsOptionsUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_RESOURCE_PARAMS_OPTIONS_API;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_RESOURCE_PARAMS_OPTIONS_API;
  }
};

export const GetEc2InstanceCostDetailsUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_EC2_INSTANCE_COST_DETAILS_API;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_EC2_INSTANCE_COST_DETAILS_API;
  }
};

export const getSecurityViolationsTypeDefinitionsUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_SECURITY_VIOLATIONS_TYPE_DEFINITION_API;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_SECURITY_VIOLATIONS_TYPE_DEFINITION_API;
  }
};

export const GetSecurityViolationsListUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_SECURITY_VIOLATIONS_LIST_API;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_SECURITY_VIOLATIONS_LIST_API;
  }
};

export const GetRegionsForAwsKeysUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_REGIONS_FOR_AWS_KEYS;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_REGIONS_FOR_AWS_KEYS;
  }
};

export const GetCognitoResetConfirmCode = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + COGNITO_USER_POOL_GET_RESET_CODE;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + COGNITO_USER_POOL_GET_RESET_CODE;
  }
};

export const GetAssetsInfoListUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_ASSETS_INFO_LIST;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_ASSETS_INFO_LIST;
  }
};

export const GetAssetInfoForResourcetUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_ASSET_INFO_FOR_RESOURCE;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_ASSET_INFO_FOR_RESOURCE;
  }
};

export const GetAssetInfoUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_ASSET_INFO;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_ASSET_INFO;
  }
};

export const GetResourcesDiffInfoUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_RESOURCES_DIFF_INFO;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_RESOURCES_DIFF_INFO;
  }
};

export const GetProductConfigurationSchedulesUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_SCHEDULED_EVENTS;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_SCHEDULED_EVENTS;
  }
};

export const GetAwsResourceIconPath = (iconPath) => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_AWS_RESOURCE_ICON_PATH + '/' + iconPath;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_AWS_RESOURCE_ICON_PATH + '/' + iconPath;
  }
};

export const GetCaptchaVerificationUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + VERIFY_CAPTCHA;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + VERIFY_CAPTCHA;
  }
};

//
//
// get_ec2_schedule_info_and_recos GET_CPU_SCHEDULE_INFO_AND_RECOMMENDATIONS
export const GetEc2ScheduleInfoAndRecommendationsUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_EC2_SCHEDULE_INFO_AND_RECOMMENDATIONS;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_EC2_SCHEDULE_INFO_AND_RECOMMENDATIONS;
  }
};

export const GetSetEc2ScheduleApprovalUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + SET_EC2_RECO_APPROVAL;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + SET_EC2_RECO_APPROVAL;
  }
};

// export const GetAiGenResponsetUrl = () => {
//   if (IsDeploymentModeProd()) {
//     return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_AI_GEN_RESPONSE;
//   } else if (IsDeploymentModeDev()) {
//     return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_AI_GEN_RESPONSE;
//   }
// };

export const GetCarloEpUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_CARLO_EP;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_CARLO_EP;
  }
};

export const getCarloIpFromBff = async () => {
  try {
    const response = await axios.post(GetCarloEpUrl(), {});
    if (response.status === 200) {
      // console.log(response.data.carlo_Llm_ip);
      return response.data.carlo_Llm_ip; // Return the entire response data
    }
  } catch (error) {
    console.error('Error fetching Carlo IP:', error);
    return null; // Return null or some default value in case of an error
  }
};

export const GetAiGenResponsetUrl = (ipaddr) => {
  return HTTPS_PROTOCOL + ipaddr + '/ml/' + GET_AI_GEN_RESPONSE;
};

export const GetAiGenQueryResponsetUrl = (ipaddr) => {
  return HTTPS_PROTOCOL + ipaddr + '/ml/' + GET_AI_GEN_QUERY;
};

export const GetSetEc2InstanceStateUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + SET_EC2_INSTANCE_STATE;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + SET_EC2_INSTANCE_STATE;
  }
};

export const GetExplainAiRecosUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + GET_EXPLAIN_AI_RECOS;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + GET_EXPLAIN_AI_RECOS;
  }
};

export const GetAiParamsUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + HANDLE_AI_PARAMS;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + HANDLE_AI_PARAMS;
  }
};

// business website

export const GetContactUsFormUrl = () => {
  if (IsDeploymentModeProd()) {
    return HTTPS_PROTOCOL + PROD_GO_BFF_BASE_URL + '/' + CONTACT_US_FORM;
  } else if (IsDeploymentModeDev()) {
    return HTTPS_PROTOCOL + DEV_GO_BFF_BASE_URL + '/' + CONTACT_US_FORM;
  }
};
