export const CREATE_NEW_AWS_API_GATEWAY = 'get_createnewawsapigw_clicmd';
export const TERRAFORM_SCRIPTS_DOWNLOAD_API = 'get_terraform_scripts';
export const SYNC_FROM_CLOUD_API = 'get_resource_data_from_cloud';
export const SYNC_FROM_CLOUD_API_V2 = 'get_resource_data_from_cloud_v2';
export const GET_RESOURCES_OVERVIEW_UI_CACHE_API = 'get_resources_overview_ui_cache';
export const POST_MY_PROFILE_API = 'myprofile';
export const POST_ACK_SET_API = 'ackset';
export const VERIFY_USER_LOGIN_API = 'verify_user_login_authorization';
export const VERIFY_COGNITO_USER_POOL_LOGIN_API = 'verify_cognito_user_pool_login_authorization';
export const GET_IAM_POLICY_FOR_DOWNLOAD_API = 'onboarding'
export const GET_COST_MANAGER_API = 'get_cost_details';
export const GET_COST_MANAGER_API_V2 = 'gcdv2';
export const GET_SIMULATED_COST_MANAGER_API = 'get_cost_details';
export const GET_ALL_ACCOUNTS_API = 'get_account_ids';
export const ADVANCED_SEARCH_API = 'get_cost_details_advance_search';
export const GET_COST_PLANNER_INFO_API = 'get_cost_planner_info';
export const GET_RESOURCE_PARAMS_OPTIONS_API = 'get_resource_params_options';
export const GET_EC2_INSTANCE_COST_DETAILS_API = 'get_ec2_instance_cost_details';
export const GET_SECURITY_VIOLATIONS_LIST_API = 'get_security_violations_list';
export const GET_SECURITY_SCAN_REQUEST_API = "smsca"
export const GET_SECURITY_VIOLATIONS_TYPE_DEFINITION_API = 'get_aws_security_violation_type_definitions';
export const CONFIRM_REGISTERED_USER_API = 'confirm_registered_user';
export const GET_REGIONS_FOR_AWS_KEYS = 'get_all_aws_regions_list';
export const GET_LATEST_RESOURCES_INFO = 'get_latest_resources_info';
export const SET_RESOURCE_PARAMS = 'set_resource_params';
export const COGNITO_USER_POOL_GET_RESET_CODE = 'cgup_get_reset_confirm_code';
export const GET_ASSETS_INFO_LIST = 'get_assets_info_list';
export const GET_ASSET_INFO_FOR_RESOURCE = 'get_asset_info_for_resource';
export const GET_ASSET_INFO = 'asset_info';
export const GET_RESOURCES_DIFF_INFO = 'get_resources_diff_info';
export const GET_SCHEDULED_EVENTS = 'scheduled_events';
export const VERIFY_CAPTCHA = 'verify_captcha';
export const CONTACT_US_FORM = 'submit_contact_info';
export const GET_AI_GEN_RESPONSE = 'get_ai_gen_response02';
export const GET_AI_GEN_QUERY = 'query_ai_model';
export const GET_EC2_SCHEDULE_INFO_AND_RECOMMENDATIONS = 'get_ec2_recos';
export const SET_EC2_RECO_APPROVAL = 'set_ec2_reco_approval';
export const SET_EC2_INSTANCE_STATE = 'set_ec2_instance_state';
export const CREATE_FRESH_RESOURCES_INFO_FOR_USER = 'create_fresh_resources_info_for_user';
export const GET_CARLO_EP = 'get_carlo_ep';
export const GET_EXPLAIN_AI_RECOS = 'explain_ai_recos';
export const HANDLE_AI_PARAMS = 'ai_params';

//
//
//
